<template>
  <Card headlineLevel="h2" heading="Thank You For Your Feedback">
    <template #image>
      <div class="gradient">
        <img loading="lazy" src="@/assets/images/Submissions/icon2.png" alt />
      </div>
    </template>
    <p>
      Thank you for helping shape our future plans. Electrify America is no longer accepting submissions on investment
      cycles, but you can learn more about how Electrify America LLC is investing over $2 billion in Zero Emission
      Vehicle (ZEV) charging infrastructure, brand-neutral ZEV education efforts, and ZEV access initiatives.
    </p>
    <router-link class="button-link" :to="{ name: 'our-plan-en' }">
      Learn More About Our Investment Plan <arrow class="arrow"></arrow>
    </router-link>
  </Card>
</template>

<script>
import Arrow from '@/assets/images/Global/arrow_dark.svg';

import Card from '@/components/Card/Card.vue';

export default {
  name: 'submissions',
  metaInfo: {
    title: 'Thank you for your submissions | Electrify America',
    meta: [
      {
        name: 'description',
        content: `Thank you for your feedback on our investment plans.`,
      },
    ],
    link: [{ rel: 'canonical', href: 'https://www.electrifyamerica.com/submissions/' }],
  },
  components: {
    Arrow,
    Card,
  },
};
</script>

<style scoped lang="scss">
.gradient {
  background-image: radial-gradient(circle, rgba($c-highlights, 0.15) 35%, rgba($c-primary-background, 1) 70%);
  background-position: center;
  height: 344px;
  width: 344px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100px;
    height: 100px;
  }
}
</style>
